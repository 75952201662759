export const userConstants = {
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_SUCCESS_LEGACY: 'USERS_LOGIN_SUCCESS_LEGACY',

    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_SUCCESS_LEGACY: 'USERS_LOGOUT_SUCCESS_LEGACY',

    ACCOUNT_DELETE_REQUEST: 'USERS_ACCOUNT_DELETE_REQUEST',
    ACCOUNT_DELETE_SUCCESS: 'USERS_ACCOUNT_DELETE_SUCCESS',
    ACCOUNT_DELETE_FAILURE: 'USERS_ACCOUNT_DELETE_FAILURE',

    JWT_LOCAL_STORAGE_KEY: 'USER',
    JWT_LOCAL_STORAGE_KEY_LEGACY: 'LANIS_PERSONAL',
};